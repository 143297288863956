import { useStaticQuery, graphql } from "gatsby";
import { GlobalFooterLargeCtaO } from "@types";
import { ImageDataLike } from "gatsby-plugin-image";

type QueryData = {
  globalFooterLargeCtaO: GlobalFooterLargeCtaO;
  footerBackgroundImage: ImageDataLike;
};

export const GlobalFooterLargeCtaQuery = (): QueryData => {
  const {
    allWp: {
      edges: [
        {
          node: {
            graphqlOptions: { globalFooterLargeCtaO },
          },
        },
      ],
    },
    wp: {
      graphqlOptions: {
        globalFooterLargeCtaO: {
          ctaBannerLargeBackgroundImage: {
            localFile: { childImageSharp },
          },
        },
      },
    },
  } = useStaticQuery(graphql`
    query GlobalFooterLargeCtaQuery {
      allWp {
        edges {
          node {
            graphqlOptions {
              globalFooterLargeCtaO {
                ctaBannerLargeCta
                ctaBannerLargeCtaText
                ctaBannerLargeList {
                  ctaBannerLargeListItem
                }
                ctaBannerLargeSubcopy
                ctaBannerLargeTitle
                ctaBannerLargeBackgroundImage {
                  mediaItemUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [WEBP, PNG, AUTO]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      wp {
        graphqlOptions {
          globalFooterLargeCtaO {
            ctaBannerLargeBackgroundImage {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [WEBP, PNG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  return {
    globalFooterLargeCtaO,
    footerBackgroundImage: childImageSharp,
  };
};

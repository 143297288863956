import * as React from "react";

const BlackCheckmark: React.FC<{ cls?: string }> = ({ cls }) => (
  <svg
    className={cls}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>List Checkmark</title>
    <path d="M9.87938 14.6219L7.27688 12.0194C6.98438 11.7269 6.51187 11.7269 6.21937 12.0194C5.92688 12.3119 5.92688 12.7844 6.21937 13.0769L9.35438 16.2119C9.64688 16.5044 10.1194 16.5044 10.4119 16.2119L18.3469 8.27687C18.6394 7.98437 18.6394 7.51187 18.3469 7.21937C18.0544 6.92688 17.5819 6.92688 17.2894 7.21937L9.87938 14.6219Z"></path>
  </svg>
);

export default BlackCheckmark;

import React, { useEffect, useState } from "react";

import { MainProvider } from "../../contexts/MainContext";
import { mxpPageViewed } from "../../utils/mixpanelEvents/mixpanelEvents";
import generateUuId from "../../utils/generateUuId";
import GlobalFooterLargeCta from "../../components/CTAs/GlobalFooterLargeCta/GlobalFooterLargeCta";
import HeaderFullMenu from "../../components/Headers/HeaderFullMenu";
import useGtmPageChange from "../../hooks/useGtmPageChange";

import "../main.scss";
import GABannerSection from "@components/GABannerSection";
import DefaultFooter from "@layouts/DefaultFooter";

declare global {
  interface Window {
    mixpanelTrialSent: boolean;
    trackMixpanelEvent: (
      event: string,
      data?: {
        [key: string]: any;
      }
    ) => void;
    grecaptcha: any;
    dataLayer: {
      push: (someObj: Object) => boolean;
    };
    gtag: any;
    fbq: any;
    google_conversion_first_name: string;
    google_conversion_last_name: string;
    google_conversion_email: string;
    google_conversion_phone_number: string;
    Grnhse: {
      Iframe: {
        load: (jobId?: number) => void;
      };
    };
    growsumo?: {
      cookie_domain: string;
      domain_v1: string;
      domain_v2: string;
      fallback_domain: string;
      initialized: boolean;
      data: {
        amount: number;
        currency: string;
        customer_key: string;
        email: string;
        host_domain: string;
        name: string;
        public_key: string;
      };
      createSignup: () => void;
    };
    Osano: {
      cm: {
        showDrawer: (s: string) => void;
      };
    };
  }
}

type LayoutWithLargeFooterCtaType = {
  children: React.ReactNode;
  pathname: string;
};

const LayoutWithLargeFooterCta: React.FC<LayoutWithLargeFooterCtaType> = ({
  children,
  pathname,
}) => {
  const uuid = generateUuId();

  useEffect(() => {
    setIsClient(true);
    mxpPageViewed();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useGtmPageChange(uuid);

  const [isClient, setIsClient] = useState(false);

  return (
    <MainProvider>
      <React.Fragment key={`layout-${isClient}`}>
        <GABannerSection />
        <HeaderFullMenu data-testid="header-full-menu" />
        <div>
          <main>
            {children}

            <GlobalFooterLargeCta />
          </main>
        </div>
        <DefaultFooter pathname={pathname} />
      </React.Fragment>
    </MainProvider>
  );
};

export default LayoutWithLargeFooterCta;

import * as React from "react";

import { GlobalFooterLargeCtaQuery } from "../../../assets/StaticQueries/GlobalFooterLargeCta.query";
import BlackCheckmark from "../../SVGIcons/BlackCheckmark/BlackCheckmark";
import { GlobalFooterLargeCtaO } from "@types";
import setRouteName from "../../../utils/getLocationName";
import DropDownWithArrow from "@components/Dropdowns/DropDownWithArrow";

import styles from "./GlobalFooterLargeCta.module.scss";

type GlobalFooterLargeCtaProps = {
  className?: string;
  withPaddingTop?: boolean;
  LargeCtaData?: GlobalFooterLargeCtaO;
};

const GlobalFooterLargeCta: React.FC<GlobalFooterLargeCtaProps> = ({
  className = "",
  withPaddingTop = true,
  LargeCtaData,
}) => {
  const {
    ctaBannerLargeCta,
    ctaBannerLargeCtaText,
    ctaBannerLargeList,
    ctaBannerLargeSubcopy,
    ctaBannerLargeTitle,
    ctaBannerLargeBackgroundImage,
  } = LargeCtaData || GlobalFooterLargeCtaQuery().globalFooterLargeCtaO;
  const bannerList =
    ctaBannerLargeList &&
    ctaBannerLargeList.map((listItem, index) => (
      <li key={index}>
        <BlackCheckmark />
        {listItem.ctaBannerLargeListItem}
      </li>
    ));
  const routeName = setRouteName();
  const backgroundImage = `url(${ctaBannerLargeBackgroundImage?.mediaItemUrl})`;

  return (
    <section
      data-testid="section:GlobalFooterLargeCta"
      className={`full-width ${
        withPaddingTop ? "pad-top--large" : ""
      }  ${className}`}
    >
      <div className={styles.container} style={{ backgroundImage }}>
        <div className={styles.bannerContent}>
          <h4 dangerouslySetInnerHTML={{ __html: ctaBannerLargeTitle }} />
          <p className={styles.text}>{ctaBannerLargeSubcopy}</p>
          <div>
            <DropDownWithArrow
              label={ctaBannerLargeCtaText}
              signUpUrl={ctaBannerLargeCta}
            />
            <ul>{bannerList}</ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalFooterLargeCta;

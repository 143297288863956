import React from "react";
import { TextStatsType } from "../../assets/Types/AcfGroupTypes/TextStatsP.type";
import DownArrowSmall from "../../components/SVGIcons/DownArrowSmall/DownArrowSmall";

import styles from "./TextStatsPContainer.module.scss";

type TextStatsPContainerProps = {
  textStatsP: TextStatsType | null;
  withWidget?: boolean;
  className?: string | null;
};

const TextStatsPContainer: React.FC<TextStatsPContainerProps> = ({
  textStatsP,
  withWidget = true,
  className = "",
}) => {
  const { statsList } = textStatsP;
  return (
    <section className={`pad-top--large ${styles.sectionContainer}`}>
      {withWidget && (
        <div className={styles.scrollMoreContainer}>
          <DownArrowSmall />
          {" Read why "}
        </div>
      )}
      <div className={`${styles.textStatsContainer} ${className}`}>
        <div className={`full-width ${styles.textStatsGrid}`}>
          {statsList &&
            statsList?.map((stat, index) => (
              <div key={index} className={styles.textStatsContent}>
                <h2>{stat.numberValue}</h2>
                <p>
                  <span
                    dangerouslySetInnerHTML={{ __html: stat.description }}
                  />
                </p>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default TextStatsPContainer;

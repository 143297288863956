import React, { useState, useEffect, useMemo } from "react";
import { Cookie } from "../../utils/Cookie";
import styles from "./GlobalBannerStyle.module.scss";
import useShowGlobalBannerStore from "../../sp-state/useShowGlobalBanner";
import useShowNavStore from "../../sp-state/useShowNavStore";
import { fireMxpEvent } from "../../utils/mixpanelEvents/mixpanelEvents";
import HTMLReactParser from "html-react-parser";
import {
  modalBannerDismissed,
  modalBannerViewed,
} from "@utils/segment/ModalBanner";

const GlobalBanner: React.FC<{
  banner: Queries.builder_GlobalAnnouncementBannerData;
}> = ({ banner }) => {
  const [shouldShow, setShouldShow] = useState(false);

  const titleSegmentEvent = "global announcement banner";

  const {
    backgroundColor: globalBannerBackgroundColor,
    cookieName: globalBannerCookieName,
    copy: globalBannerCopy,
    copyColor: globalBannerCopyColor,
  } = banner;

  const showNav = useShowNavStore(state => state.showNav);
  const showGlobalBanner = useShowGlobalBannerStore(
    state => state.showGlobalBanner
  );

  const backgroundColor = globalBannerBackgroundColor
    ? globalBannerBackgroundColor
    : "#ffff";

  const copyColor = globalBannerCopyColor ? globalBannerCopyColor : "#ffff";

  useEffect(() => {
    if (typeof window === "undefined") return;

    modalBannerViewed(titleSegmentEvent);

    const getBannerCookie = Cookie.getCookie(globalBannerCookieName);

    if (!getBannerCookie && globalBannerCopy) {
      setShouldShow(true);
    }
  }, [copyColor, globalBannerCookieName, globalBannerCopy]);

  const handleCloseBanner = () => {
    Cookie.setCookie(globalBannerCookieName, true, 1);

    modalBannerDismissed(titleSegmentEvent);

    fireMxpEvent("user: marketing website banner - closed", {
      sp_page: window.location.pathname,
    });

    setShouldShow(false);
  };

  const fireMxpLink = (sp_text: string, sp_target: string) => {
    fireMxpEvent("user: marketing website banner - link clicked", {
      sp_text,
      sp_target,
      sp_page: window.location.pathname,
    });
  };

  const globalBannerCopyHTML = useMemo(() => {
    if (!globalBannerCopy) return null;

    const html = HTMLReactParser(globalBannerCopy, {
      replace: ({ attribs, children }) => {
        if (attribs && attribs.href) {
          const text = children[0]?.data;

          let linkWithText = styles.linkWithTextPaddingRightOnly;
          if (
            globalBannerCopy.indexOf("</a>") ===
            globalBannerCopy.length - 4
          ) {
            linkWithText = styles.linkWithTextPaddingLeftOnly;
          } else if (globalBannerCopy.indexOf("<a") > 0) {
            linkWithText = styles.linkWithTextPadding;
          }

          return (
            <>
              <a
                href={attribs.href}
                onClick={() => fireMxpLink(text, attribs.href)}
                className={linkWithText}
              >
                {text}
              </a>
            </>
          );
        }
      },
    });

    return html;
  }, [globalBannerCopy]);

  return shouldShow && !showNav && showGlobalBanner ? (
    <div
      className={styles.globalBannerContainer}
      style={{
        backgroundColor,
        color: globalBannerCopy!,
      }}
    >
      {globalBannerCopy && (
        <div
          className={styles.globalBannerCopy}
          style={{
            "--text-color": copyColor || "#ffff",
          }}
        >
          {globalBannerCopyHTML}
        </div>
      )}
      <button
        className={styles.globalBannerCloseIcon}
        onClick={handleCloseBanner}
      >
        <img src="/icons/close-icon.svg" alt="Close Icon" />
      </button>
    </div>
  ) : null;
};

export default GlobalBanner;

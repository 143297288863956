import * as React from "react";
import { graphql, PageProps } from "gatsby";
import HeroImagePContainer from "../../containers/HeroImagePContainer/HeroImagePContainer";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";
import { HeroImagePType } from "../../assets/Types/AcfGroupTypes/HeroImageP.type";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import { FeaturesModulePType } from "../../assets/Types/AcfGroupTypes/FeaturesModuleP/FeaturesModuleP.type";
import { TextStatsType } from "../../assets/Types/AcfGroupTypes/TextStatsP.type";
import { YoastSEOType } from "../../assets/Types";
import TextStatsPContainer from "../../containers/TextStatsPContainer/TextStatsPContainer";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

type AboutUsProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      heroImageP: HeroImagePType;
      featuresModuleP: FeaturesModulePType;
      textStatsP: TextStatsType;
    };
    title: string;
  };
};

const AboutUsPage: React.FC<PageProps<AboutUsProps>> = ({ data, location }) => {
  const { wpPage } = data;

  const {
    template: { heroImageP, featuresModuleP, textStatsP },
    title,
  } = wpPage;

  const hasTextStats = textStatsP.statsList;

  featuresModuleP.featuresModuleList.forEach(item => {
    item.featuresModuleAssetToggle = "image";
  });

  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <HeroImagePContainer
        background="pink"
        heroProps={heroImageP}
        withButton={false}
        subBannerText="Keep reading"
      />
      <section className="partners-section">
        <h2 className="extra-pad-top" />
        <FeaturesModulePContainer
          props={featuresModuleP}
          page={title}
          withPaddingB={false}
        />
      </section>
      {hasTextStats && (
        <TextStatsPContainer
          textStatsP={textStatsP}
          withWidget={false}
          className="text-stats-container"
        />
      )}
    </LayoutWithLargeFooterCta>
  );
};

export default AboutUsPage;

export const query = graphql`
  query AboutUsTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpAboutUsTemplate {
          templateName
          heroImageP {
            title: heroImageTitle
            subCopy: heroImageSubcopy
            videoId: heroImageVideoId
            videoImg: heroImageImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    formats: [WEBP, JPG, AUTO]
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          featuresModuleP: partnersGridP {
            featuresModuleTitle: partnersGridTitle
            featuresModuleList: partnersGridList {
              featuresModuleItemTitle: gridListTitle
              featuresModuleItemSubcopy: gridListSubcopy
              gridListImageAltText
              featuresModuleItemImage: gridListImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      formats: [WEBP, JPG, AUTO]
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          textStatsP {
            statsList {
              description
              fieldGroupName
              numberValue
            }
          }
        }
      }
    }
  }
`;

import { create } from "zustand";

type UseShowGlobalBannerType = {
  showGlobalBanner: boolean;
  setShowGlobalBanner: (val: boolean) => void;
};

const useShowGlobalBannerStore = create<UseShowGlobalBannerType>(
  set => ({
    showGlobalBanner: true,
    setShowGlobalBanner: val => set(_ => ({ showGlobalBanner: val })),
  })
);

export default useShowGlobalBannerStore;

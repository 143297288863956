import React, { createContext, useState, useContext, useCallback } from "react";

interface IMainProviderProps {
  children: React.ReactNode;
}
interface IMainContextData {
  hasHeaderShadow: boolean;
  handleHeaderShadow: (hasBoxShadow: boolean) => void;
}

export const MainContext = createContext<IMainContextData>(
  {} as IMainContextData
);

export const MainProvider = ({ children }: IMainProviderProps): JSX.Element => {
  const [hasHeaderShadow, setHasHeaderShadow] = useState(true);

  const handleHeaderShadow = useCallback((hasBoxShadow: boolean) => {
    setHasHeaderShadow(hasBoxShadow);
  }, []);

  const dataToShare = {
    hasHeaderShadow,
    handleHeaderShadow,
  };

  return (
    <MainContext.Provider value={dataToShare}>{children}</MainContext.Provider>
  );
};

export const useMainContext = () => useContext(MainContext);

import * as React from "react";

const DownArrowSmall: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    data-testid="svg:DownArrowSmall"
    className={`icon ${className}`}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1727 12.3864L15.9993 17.5598L10.826 12.3864C10.7026 12.263 10.556 12.1651 10.3947 12.0983C10.2334 12.0315 10.0606 11.9971 9.886 11.9971C9.71143 11.9971 9.53856 12.0315 9.37728 12.0983C9.21599 12.1651 9.06944 12.263 8.946 12.3864C8.82256 12.5099 8.72464 12.6564 8.65783 12.8177C8.59103 12.979 8.55664 13.1519 8.55664 13.3264C8.55664 13.501 8.59103 13.6739 8.65783 13.8352C8.72464 13.9964 8.82256 14.143 8.946 14.2664L15.066 20.3864C15.586 20.9064 16.426 20.9064 16.946 20.3864L23.066 14.2664C23.1896 14.1431 23.2877 13.9966 23.3546 13.8353C23.4215 13.674 23.4559 13.5011 23.4559 13.3264C23.4559 13.1518 23.4215 12.9789 23.3546 12.8176C23.2877 12.6563 23.1896 12.5098 23.066 12.3864C22.546 11.8798 21.6927 11.8664 21.1727 12.3864V12.3864Z"
      fill="#333333"
    ></path>
  </svg>
);

export default DownArrowSmall;

import { Builder } from "@builder.io/react";
import * as React from "react";
import GlobalBanner from "../../GlobalBanner/GlobalBanner";

const GlobalBannerSectionBC: React.FC<Queries.builder_GlobalAnnouncementBannerData> =
  props => <GlobalBanner banner={props} />;

export default GlobalBannerSectionBC;

export const RegisterGlobalBannerSection = () => {
  Builder.registerComponent(GlobalBannerSectionBC, {
    name: "global-announcement-banner",
    inputs: [
      {
        name: "backgroundColor",
        type: "color",
        defaultValue: "#FFF",
      },
      {
        name: "copyColor",
        type: "color",
        defaultValue: "#000",
      },
      {
        name: "copy",
        type: "text",
        defaultValue: "",
      },
      {
        name: "cookieName",
        type: "text",
        defaultValue: "",
      },
    ],
  });
};
